import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "allReviews",
    initialState,
    reducers: {
        allReviewsRequest: (allReviews) => {
            allReviews.loading = true;
            allReviews.error = false;
        },
        allReviewsClear: (allReviews) => {
            allReviews.loading = false;
            allReviews.error = false;
        },
        allReviewsSuccess: (allReviews, action) => {
            allReviews.loading = false;
            allReviews.error = false;
            allReviews.data = action.payload;
        },
        allReviewsError: (allReviews, action) => {
            allReviews.loading = false;
            allReviews.error = action.payload;
        }
    }
});

export const { allReviewsRequest, allReviewsClear, allReviewsSuccess, allReviewsError } = slice.actions;

export default slice.reducer;
