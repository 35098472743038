import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        AuthRequest: (auth) => {
            auth.loading = true;
            auth.error = false;
        },
        AuthClear: (auth) => {
            auth.error = false;
            auth.loading = false;
        },
        AuthSuccess: (auth, action) => {
            auth.loading = false;
            auth.error = false;
            auth.data = action.payload;
        },
        AuthError: (auth, action) => {
            auth.loading = false;
            auth.error = action.payload;
        }
    }
});

export const { AuthRequest, AuthClear, AuthSuccess, AuthError } = slice.actions;

export default slice.reducer;
