import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "courseNumberOfEnrollments",
    initialState,
    reducers: {
        CourseNumberOfEnrollmentsRequest: (courseNumberOfEnrollments) => {
            courseNumberOfEnrollments.loading = true;
            courseNumberOfEnrollments.error = false;
        },
        CourseNumberOfEnrollmentsClear: (courseNumberOfEnrollments) => {
            courseNumberOfEnrollments.error = false;
            courseNumberOfEnrollments.loading = false;
        },
        CourseNumberOfEnrollmentsSuccess: (courseNumberOfEnrollments, action) => {
            courseNumberOfEnrollments.loading = false;
            courseNumberOfEnrollments.error = false;
            courseNumberOfEnrollments.data = action.payload;
        },
        CourseNumberOfEnrollmentsError: (courseNumberOfEnrollments, action) => {
            courseNumberOfEnrollments.loading = false;
            courseNumberOfEnrollments.error = action.payload;
        }
    }
});

export const { CourseNumberOfEnrollmentsClear, CourseNumberOfEnrollmentsRequest, CourseNumberOfEnrollmentsSuccess, CourseNumberOfEnrollmentsError } = slice.actions;

export default slice.reducer;
