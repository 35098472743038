import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "authenticationList",
    initialState,
    reducers: {
        AuthenticationListRequest: (authenticationList) => {
            authenticationList.loading = true;
            authenticationList.error = false;
        },
        AuthenticationListClear: (authenticationList) => {
            authenticationList.error = false;
            authenticationList.loading = false;
        },
        AuthenticationListSuccess: (authenticationList, action) => {
            authenticationList.loading = false;
            authenticationList.error = false;
            authenticationList.data = action.payload;
        },
        AuthenticationListError: (authenticationList, action) => {
            authenticationList.loading = false;
            authenticationList.error = action.payload;
        }
    }
});

export const { AuthenticationListRequest, AuthenticationListClear, AuthenticationListSuccess, AuthenticationListError } = slice.actions;

export default slice.reducer;
