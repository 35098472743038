import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "courseEnroll",
    initialState,
    reducers: {
        CourseEnrollRequest: (courseEnroll) => {
            courseEnroll.loading = true;
            courseEnroll.error = false;
        },
        CourseEnrollClear: (courseEnroll) => {
            courseEnroll.error = false;
            courseEnroll.loading = false;
        },
        CourseEnrollSuccess: (courseEnroll, action) => {
            courseEnroll.loading = false;
            courseEnroll.error = false;
            courseEnroll.data = action.payload;
        },
        CourseEnrollError: (courseEnroll, action) => {
            courseEnroll.loading = false;
            courseEnroll.error = action.payload;
        }
    }
});

export const { CourseEnrollClear, CourseEnrollRequest, CourseEnrollSuccess, CourseEnrollError } = slice.actions;

export default slice.reducer;
