import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: [],
    loading: false,
    data: false
};

const slice = createSlice({
    name: "authenticationPublic",
    initialState,
    reducers: {
        AuthPublicRequest: (authPublic) => {
            authPublic.loading = true;
            authPublic.error = false;
        },
        AuthPublicClear: (authPublic) => {
            authPublic.error = false;
            authPublic.loading = false;
        },
        AuthPublicSuccess: (authPublic, action) => {
            authPublic.loading = false;
            authPublic.error = false;
            authPublic.data = action.payload;
        },
        AuthPublicError: (authPublic, action) => {
            authPublic.loading = false;
            authPublic.error = action.payload;
        }
    }
});

export const { AuthPublicError, AuthPublicSuccess, AuthPublicClear, AuthPublicRequest } = slice.actions;

export default slice.reducer;
