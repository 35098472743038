import { combineReducers } from "@reduxjs/toolkit";
import user from "./reducers/authentication/authentication";
import userPublic from "./reducers/authentication/authenticationPublic";
import userList from "./reducers/authentication/authenticationList";
import courses from "./reducers/courses/courses";
import courseDetails from "./reducers/courses/courseDetails";
import courseReview from "./reducers/courses/courseReview";
import allReviews from "./reducers/courses/AllReviews";
import courseProgress from "./reducers/courses/courseProgress";
import courseOutline from "./reducers/courses/courseOutline";
import courseEnroll from "./reducers/courses/courseEnroll";
import downloadReq from "./reducers/courses/downloadReq";
import addReview from "./reducers/courses/addReview";
import contentBlocks from "./reducers/courses/contentBlocks";
import courseEnrollmentList from "./reducers/courses/courseEnrollmentList";
import courseNumberOfEnrollments from "./reducers/courses/courseNumberOfEnrollments";

const rootReducer = combineReducers({
    user,
    userPublic,
    userList,
    courses,
    courseDetails,
    courseReview,
    allReviews,
    downloadReq,
    courseProgress,
    courseOutline,
    courseEnroll,
    addReview,
    contentBlocks,
    courseEnrollmentList,
    courseNumberOfEnrollments
});

export default rootReducer;
